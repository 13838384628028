<template>
  <CRow>
    <CCol col="12">
      <Toast ref="mensajeToast"></Toast>
      <Alerts ref="alert"></Alerts>
      <ModalPDF ref="visorPDF"></ModalPDF>
      <transition name="slide">
        <CCard class="border-warning">
          <CCardHeader>
            <strong>Carnet Universitario</strong>
            <div class=" card-header-actions">
              <form class="form-inline">
                <b>Filas por p&aacute;gina</b>
                <select class="form-control mr-2 ml-1" @change="sizeChange($event)"
                        v-model="filasPorPagina">
                  <option v-for="option in sizeoptions" :value="option.value">
                    {{ option.text }}
                  </option>
                </select>

                <button class="btn btn-outline-dark mr-2" type="button"
                        @click="borrarFiltroBtnOnClick()">
                  <font-awesome-icon icon="fa-sort-amount-up-alt"/>
                  Quitar Filtro
                </button>

                <button class="btn btn-outline-success ml-2" data-html="true"
                        data-toggle="tooltip"
                        title="Para exportar todos los datos en un archivo Excel<br/>seleccione la opci&oacute;n <u>Todos</u> en <b>Filas por p&aacute;gina</b>"
                        type="button"
                        @click="excelBtnOnClick()">
                  <font-awesome-icon icon="fa-file-excel"/>
                  Exportar a Excel
                </button>
              </form>
            </div>
          </CCardHeader>
          <CCardBody class="p-0 pr-1">
            <JqxGrid :width="'100%'" ref="gridSystem"
                     :source="dataAdapter"
                     :autoheight="true" :autorowheight="false" :rowsheight="50"
                     :pageable="true"
                     :pagesize="parseInt(filasPorPagina)"
                     :virtualmode="true"
                     :rendergridrows="rendergridrows"
                     :columns="columns" :enablebrowserselection="true"
                     :enabletooltips="true" :filterable="true"
                     :showfilterrow="true"
                     :sortable="true"
                     :pagermode="'simple'" :localization="localization"
                     :theme="'bootstrap'" :columnsresize="true"/>
          </CCardBody>
        </CCard>
      </transition>
    </CCol>
    <CModal
        :centered="true"
        :closeOnBackdrop="false"
        :fade="true"
        :no-close-on-backdrop="true"
        :show.sync="modalEditar"
        size="xl"
    >
      <form ref="frmNuevo" id="frmNuevo" novalidate method="post">
      <div class="row">
        <div class="col-8">
          <div class="row">
            <div class="col-3">Código:</div><div class="col-9"><strong>{{ credencial.codigo_alumno }}</strong></div>
            <div class="col-3">Nombre:</div><div class="col-9"><strong>{{ credencial.nombres }}</strong></div>
            <div class="col-3">Fecha Nac.:</div><div class="col-9"><strong>{{ credencial.fecha_nacimiento }}</strong></div>
            <div class="col-3">Carrera:</div><div class="col-9"><strong>{{ credencial.carrera }}</strong></div>
          </div>
        </div>
        <div class="col-4">
          <img :src="credencial.foto" class="img-responsive img-thumbnail" alt="" style="max-height: 50px">
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-2">C.I.:</div>
        <div class="col-5">
          <input type="text" v-model="credencial.documento_identificacion" class="form-control"
                 name="documento_identificacion" required></div>
        <div class="col-2">Expedido:</div>
        <div class="col-3"><input type="text" v-model="credencial.expedido" class="form-control"
                name="expedido"></div>
      </div>
      <div class="row mt-2">
        <div class="col-2">Nueva Foto:</div>
        <div class="col-5">
          <input type="file" accept="image/png, image/jpeg" name="foto"
          :required="credencial.foto===''" class="form-control">
        </div>
        <div class="col-2">Vigencia:</div>
        <div class="col-3"><input type="text" v-model="credencial.vigencia" class="form-control"
                                  name="vigencia" required maxlength="30"></div>
      </div>
      <div class="row mt-2">
        <div class="col-12 text-center">
          <button type="button" class="btn btn-success mr-4" @click="store()">Generar Credencial</button>
          <button type="button" class="btn btn-outline-dark ml-4" @click="modalEditar=false">Regresar a la pantalla anterior</button>
        </div>
      </div>
        <template v-if="credencial.foto!==''">
          <hr/>
          <div class="row">
            <div class="col-5">
              <img :src="pathImage(1)" alt="" class="img-responsive img-thumbnail">
            </div>
            <div class="col-2 text-center">
              <a :href="pathImage(1)" download="download" target="_blank" class="btn btn-outline-primary mt-5">
                <- Descargar
              </a>
              <a :href="pathImage(2)" download="download" target="_blank" class="btn btn-outline-primary mt-5">
                Descargar ->
              </a>
            </div>
            <div class="col-5">
              <img :src="pathImage(2)" alt="" class="img-responsive img-thumbnail">
            </div>
          </div>
        </template>
      </form>
      <template #header>
        <h6 class="modal-title">
          Carnet Universitario
        </h6>
        <CButtonClose @click="modalEditar=false"/>
      </template>
      <template #footer-wrapper><span></span></template>
    </CModal>
  </CRow>
</template>

<script>
import axios from 'axios'
import JqxGrid from "jqwidgets-scripts/jqwidgets-vue/vue_jqxgrid";
import jqxInput from "jqwidgets-scripts/jqwidgets-vue/vue_jqxinput";
import Toast from "@/views/notifications/Toast";
import Alerts from "@/views/notifications/Alerts";
import ModalPDF from "@/views/notifications/ModalPDF";

var sourceLst;
var address;
var modalPdf;
var pagesizeoptions = [6, 10, 50, 100, 500, 1000, 5000, 10000];
var pagesize = pagesizeoptions[0];

export default {
  name: 'Alumnos',
  components: {
    ModalPDF,
    Alerts,
    Toast,
    JqxGrid,
    jqxInput
  },
  data: () => {
    return {
      modalEditar: false,
      items: [],
      currentPage: 1,
      perPage: 7,
      totalRows: 0,
      filasPorPagina: pagesizeoptions[0],
      sizeoptions: [],
      sedeSeleccionada: localStorage.sedeSeleccionada,
      credencial: {
        codigo_alumno: '',
        nombres: '',
        fecha_nacimiento: '',
        carrera: '',
        foto: '',
        documento_identificacion: '',
        expedido: '',
        foto2: '',
        vigencia: '',
      },
      dataAdapter: new jqx.dataAdapter(sourceLst, {
        loadError: function (xhr, status, error) {
          location.href = location.protocol + "//" + location.host + "/#/login";
        }
      }),
      rendergridrows: (params) => {
        return params.data;
      },
      columns: [
        {
          text: 'Código Alumno', datafield: "codigo_alumno", width: '6%', cellsalign: 'center',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Código<br />Alumno</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Código Alumno"});
          }
        },
        {
          text: 'Apellido1', datafield: "apellido1", width: '13%',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Primer<br />apellido</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Apellido1"});
          }
        },
        {
          text: 'Apellido2', datafield: "apellido2", width: '13%',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Segundo<br />apellido</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Apellido2"});
          }
        },
        {
          text: 'Nombres', datafield: "nombres", width: '18%',
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Nombres"});
          }
        },
        {
          text: 'Carrera', datafield: "carrera", width: '27%',
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Carrera"});
          }
        },
        {
          text: 'Gestion', datafield: "gestion", width: '9%', cellsalign: 'center',
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Gestion"});
          }
        },
        {
          text: 'Estado', datafield: "estado", width: '4%', cellsalign: 'center',
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Estado"});
          }
        },
        {
          text: "", datafield: 'hojaruta', width: '10%', columntype: "button",
          filterable: false, sortable: false,
          cellclassname: "btn-green",
          renderer: function () {
            return '';
          },
          cellsrenderer: function (index, datafield, value, defaultvalue, column, rowdata) {
            return "Carnet";
          },
          buttonclick: function (row, event) {
            let button = $(event.currentTarget);
            let grid = button.parents("[id^=jqxGrid]");
            let rowData = grid.jqxGrid('getrowdata', row);
            let html = '';
              modalPdf.$refs.alert.show('Editar credencial');
              axios.get(
                  address + '/api/credencial/' + rowData.codigo_alumno + '/editar?token=' + localStorage.getItem("api_token")
              )
                  .then((response) => {
                    modalPdf.$refs.alert.hide();
                    modalPdf.credencial=response.data

                    modalPdf.modalEditar=true;
                  })
                  .catch(function (error) {
                    modalPdf.$refs.alert.hide();
                    modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
                  });
          }
        },
      ],
      localization: {
        'emptydatastring': "No existen datos para esta SEDE",
      },
    }
  },
  beforeCreate: function () {
    const url = this.$apiAdress + '/api/credencial/' + localStorage.sedeSeleccionada + '/listar?token=' + localStorage.getItem("api_token") + '&sede_id=' + localStorage.sedeSeleccionada;
    address = this.$apiAdress;
    sourceLst = {
      url: url,
      datafields: [
        {name: 'inscripcion_id', type: "int"},
        {name: 'codigo_alumno', type: "string"},
        {name: 'apellido1', type: "string"},
        {name: 'apellido2', type: "string"},
        {name: 'nombres', type: "string"},
        {name: 'carrera', type: "string"},
        {name: 'gestion', type: "string"},
        {name: 'estado', type: "string"},
        {name: 'documentos', type: "string"},
        {name: 'compromisos', type: "string"},
        {name: 'contratos', type: "string"},
        {name: 'hojaruta', type: "string"},
        {name: 'editar', type: "string"},
        {name: 'eliminar', type: "string"},
      ],
      data: {
        sede: this.sede
      },
      datatype: "json",
      root: "Rows",
      id: 'codigo_alumno',
      sortcolumn: 'apellido1',
      sortdirection: 'asc',
      cache: false,
      beforeprocessing: (data) => {
        if (data != null) {
          sourceLst.totalrecords = data[0].TotalRows;
          let i = 0;
          this.sizeoptions = [];
          while (i < 8 && pagesizeoptions[i] < data[0].TotalRows) {
            this.sizeoptions[i] = {text: pagesizeoptions[i], value: pagesizeoptions[i]};
            i++;
          }
          if (i > 1) {
            this.sizeoptions[i] = {text: "TODOS", value: data[0].TotalRows};
          }
        }
        modalPdf.$refs.alert.hide();
      },
      filter: () => {
        this.$refs.gridSystem.updatebounddata("filter");
      },
      sort: () => {
        this.$refs.gridSystem.updatebounddata("sort");
      },
    };
  },
  methods: {
    hasRole(roleUser) {
      let roles = localStorage.getItem("roles");
      if (roles != null) {
        roles = roles.split(',')
        if (roles != null && roles.indexOf(roleUser) >= 0) {
          return true
        }
      }
      return false;
    },
    showHideColumn(role) {
      try {
        if (!modalPdf.hasRole(role)) {
          this.$refs.gridSystem.hidecolumn(role);
        }
      } catch (e) {
      }
    },
    excelBtnOnClick: function () {
      this.$refs.gridSystem.hidecolumn('documentos');
      this.$refs.gridSystem.hidecolumn('editar');
      this.$refs.gridSystem.hidecolumn('eliminar');
      let date = new Date();
      let dateStr =
          date.getFullYear() + "" +
          ("00" + (date.getMonth() + 1)).slice(-2) + "" +
          ("00" + date.getDate()).slice(-2) + "_" +
          ("00" + date.getHours()).slice(-2) + "" +
          ("00" + date.getMinutes()).slice(-2) + "" +
          ("00" + date.getSeconds()).slice(-2);
      this.$refs.gridSystem.exportdata('xls', 'Inscritos' + dateStr, true, null, true, modalPdf.$apiAdress + "/api/reporte/exportarxls/" + '?token=' + localStorage.getItem("api_token"));
      this.$refs.gridSystem.showcolumn('documentos');
      this.$refs.gridSystem.showcolumn('editar');
      this.$refs.gridSystem.showcolumn('eliminar');
    },
    nuevoBtnOnClick: function () {
      this.$router.push({path: '/inscripcion/nuevo'});
    },
    borrarFiltroBtnOnClick: function () {
      this.$refs.gridSystem.clearfilters();
    },
    sizeChange: function (event) {
      try {
        modalPdf.$refs.alert.show("Actualizando datos");
        this.$refs.gridSystem.pagesize = parseInt(this.filasPorPagina);
        this.$refs.gridSystem.updatebounddata();
      } catch (e) {}
    },
    store: function () {
      if (!modalPdf.$refs.frmNuevo.checkValidity()) {
        modalPdf.$refs.frmNuevo.classList.add('was-validated');
      } else {
        let form_data = new FormData(document.getElementById('frmNuevo'));
        form_data.append('_method', 'POST');
        form_data.append('codigo_alumno', modalPdf.credencial.codigo_alumno);
        axios.post(this.$apiAdress + '/api/credencial/store?token=' + localStorage.getItem("api_token"),
            form_data)
            .then(function (response) {
              if(response.data.foto!==''){
                modalPdf.credencial.foto=response.data.foto;
              }
            })
            .catch(function (error) {
              modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
            });
      }
    },
    pathImage:function (pagina) {
      if(pagina===1) {
        return this.$apiAdress + '/api/credencial/' + modalPdf.credencial.codigo_alumno + '/1/generacredencial?token=' + localStorage.getItem('api_token') + '&_r=' + Math.floor(Math.random() * 100) + 1
      }else {
        return this.$apiAdress + '/api/credencial/' + modalPdf.credencial.codigo_alumno + '/2/generacredencial?token=' + localStorage.getItem('api_token') + '&_r=' + Math.floor(Math.random() * 100) + 1
      }
    }
  },
  mounted() {
    modalPdf = this;
    this.$root.$on('sedeSeleccionada', data => {
      left.sede = data;
    });
  },
}
</script>